import React, { useState } from 'react';
import { Table as StrapTable } from 'reactstrap';
import styled from "styled-components";
import isObject from "lodash/isObject";
import isNil from "lodash/isNil";
import isArray from "lodash/isArray";
import Pagination from './Pagination';

const Title = styled.div`
  margin: 10px 0 10px 30px;
  font-size: x-large;
`;

const StyledTable = styled(StrapTable)`
  margin: 0 30px 0 30px;
  table-layout: fixed;
`;

const StyledHeader = styled.th`
  position: sticky;
  position: -webkit-sticky;
  top: 10px;
  z-index: 10;
  background-color: #F564F5;
  color: white;
  padding: 10px;
  border-radius: 3%;
`;

const TableRow = styled.tr`
   &[data-selected] {
       background-color: #32B7FF4C;
    }
`;

const StyledCell = styled.td`
  padding: 10px;
  height: 60px;
  border: 1px solid #FFB5FD;
  border-radius: 3%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;   
  text-overflow: ellipsis;
  max-width: 300px;
  max-height: 200px;
  transition: all 0.3s ease; 
  
  ${({ isHovered }) => isHovered && `
  max-width: 500px;        /* Allow the cell to expand to show full content */
  white-space: normal;    /* Allow the content to wrap */
  padding: 20px;          /* Increase padding to give space for the content */
  text-overflow: unset;    /* Remove ellipsis on hover */
  overflow: visible;       /* Allow content to overflow and expand */
  word-wrap: break-word;  /* Ensure long words break onto the next line */
  overflow-wrap: break-word; /* For newer browsers */
  `}
    
`;

const FilterInput = styled.input`
  margin: 5px;
  padding: 5px;
  border-radius: 3%;
  border: 1px solid #FFB5FD;
`;

const formatCell = (item) => {
    if (isNil(item)) {
        return "null";
    }
    if (isArray(item)) {
        return `[${item.map(subItem => formatCell(subItem)).join(", ")}]`;
    }
    if (isObject(item)) {
        let keys = Object.keys(item);
        return `{${keys.map(key => `${key}: ${formatCell(item[key])}`).join(", ")}}`;
    }
    let itemStr = item.toString();
    return itemStr;
};
const Table = ({ header, rows, fileName }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [filters, setFilters] = useState(Array(header.length).fill(""));
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [hoveredCell, setHoveredCell] = useState(null);

    const handleFilterChange = (index, value) => {
        const newFilters = [...filters];
        newFilters[index] = value;
        setFilters(newFilters);
        setCurrentPage(0); // Reset to first page on filter change
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(Number(event.target.value));
        setCurrentPage(0); // Reset to first page on rows per page change
    };

    const filteredRows = rows.filter(row =>
        row.every((cell, index) =>
            formatCell(cell).toLowerCase().includes(filters[index].toLowerCase())
        )
    );

    const pageCount = Math.ceil(filteredRows.length / rowsPerPage);
    const offset = currentPage * rowsPerPage;
    const currentRows = filteredRows.slice(offset, offset + rowsPerPage);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <React.Fragment>
            <Title>{fileName}</Title>
            <div style={{ margin: '0 30px 10px 30px', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    Show 
                    <select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ margin: '0 10px' }}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                    </select>
                    rows per page
                </div>
                <div>
                    Showing {currentRows.length} of {filteredRows.length} rows
                </div>
            </div>
            <StyledTable>
                <thead>
                    <tr>
                        {header.map((column, index) => (
                            <StyledHeader key={column}>
                                {column}
                                <FilterInput
                                    type="text"
                                    value={filters[index]}
                                    onChange={(e) => handleFilterChange(index, e.target.value)}
                                    placeholder={`Filter ${column}`}
                                />
                            </StyledHeader>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((row, index) => (
                        <TableRow
                            onClick={() => setSelectedRow(index)}
                            key={index}
                            data-selected={selectedRow === index ? true : null}
                        >
                            {row.map((item, subIndex) => (
                                <StyledCell
                                    key={`${index}.${subIndex}`}
                                    title={formatCell(item)}
                                    isHovered={hoveredCell?.row === index && hoveredCell?.col === subIndex}
                                    onMouseEnter={() => setHoveredCell({ row: index, col: subIndex })}
                                    onMouseLeave={() => setHoveredCell(null)}
                                >
                                    {formatCell(item)}
                                </StyledCell>
                            ))}
                        </TableRow>
                    ))}
                </tbody>
            </StyledTable>
            <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onPageChange={handlePageClick}
            />
        </React.Fragment>
    );
};

export default Table;